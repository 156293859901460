import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
/* import * as RNLocalize from "react-native-localize"; */
import SecureLocalStorage from "react-secure-storage";
//import LanguageDetector from 'i18next-browser-languagedetector'
import { useTranslation } from "react-i18next";

import en from './translations/en';
import fr from './translations/fr';
import nl from "./translations/nl";

const LANGUAGES = {
  en,
  fr,
  nl
};

const LANG_CODES = Object.keys(LANGUAGES);
/* const deviceLocales = RNLocalize.getLocales(); */

const LANGUAGE_DETECTOR = {

  type: 'languageDetector',
  async: true,
/*   detect: callback => {
    SecureLocalStorage.getItem("user-language", (err, language) => {
      if (err || !language) {
        if (err) {
          console.log("Error fetching Languages from asyncstorage ", err);
        } else {
          console.log("No language is set, choosing French as fallback");
        }
        if (LANG_CODES.indexOf(deviceLocales[0].languageCode) === -1) {
          callback("fr");
        } else {
          callback(deviceLocales[0].languageCode);
        }
        return;
      }
      callback(language);
    });
  }, */
  init: () => { },
  cacheUserLanguage: language => {
    console.log(language)
    SecureLocalStorage.setItem("user-language", language);
  }
};

i18n
  // detect language
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    fallbackLng: "en",
    resources: LANGUAGES,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    },
    defaultNS: 'common',
    detection: LANGUAGE_DETECTOR
  });

