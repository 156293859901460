const config = {
  dns: "buybye.reckon.ai",
  //dns_app: "reckon-adyen.buybye.app",
  dns_app: "lekkerland-terminal.buybye.app",
  useLocale: true,
  buybyeURL: "https://buybye-testing-qr.reckon.ai/app",
  authURL: "https://auth-qr.reckon.ai/app",
  changeLocale: true,
  privacyPolicyUrl:
    "https://carrefour-terms.reckon.ai/lekker-privacy-policy.html",
  termAndConditionsUrl: 
  "https://carrefour-terms.reckon.ai/lekker-terms-and-conditions.html"
};

export default config;