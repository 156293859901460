import React from "react";
import { View, StyleSheet } from "react-native-web";

import DefaultTheme from "../themes";
import Button from "../components/Button";
import BottomBtnContainer from "./UI/BottomBtnContainer";
import H1Title from "./UI/H1Title";
import Paragraph from "./UI/Paragraph";

const ResultMessages = ({
  style,
  image,
  title,
  text,
  additionalCodeBlock = null,
  primaryButton,
  secondaryButton,
  linkButton,
  loading,
}) => {
  return (
    <View style={[{ flex: 1 }, style]}>
      <View style={[styles.image_container, image[1]]}>{image[0]}</View>
      <View style={styles.text_container}>
        <H1Title style={{ marginTop: 15 }}>{title}</H1Title>
        <Paragraph>{text}</Paragraph>
      </View>
      {additionalCodeBlock && (
        <View style={{ marginVertical: 20 }}>{additionalCodeBlock}</View>
      )}
      <BottomBtnContainer style={{ flex: 2 }}>
        {primaryButton && (
          <View style={{ marginTop: 24 }}>
            <Button
              text={primaryButton[0]}
              onPress={primaryButton[1]}
              Icon={primaryButton[2]}
              type="PRIMARY"
              loading={loading}
              element={loading ? "" : null}
            />
          </View>
        )}
        {secondaryButton && (
          <View style={{ marginTop: 24 }}>
            <Button
              text={secondaryButton[0]}
              onPress={secondaryButton[1]}
              Icon={secondaryButton[2]}
              type="SECONDARY"
              loading={loading}
              element={loading ? "" : null}
            />
          </View>
        )}
        {linkButton && (
          <View style={{ marginTop: 22 }}>
            <Button
              text={linkButton[0]}
              onPress={linkButton[1]}
              Icon={linkButton[2]}
              type="LINK"
              element={loading ? "" : null}
            />
          </View>
        )}
      </BottomBtnContainer>
    </View>
  );
};

export default ResultMessages;

const styles = StyleSheet.create({
  image_container: {
    flex: 3,
    justifyContent: "end",
    alignItems: "center",
  },
  text_container: { 
    flex: 2, 
    justifyContent: "flex-start", 
  },
});
