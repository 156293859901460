import React from "react";
import { StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";

const H1Title = ({ children, style}) => {
    return(
        <Text style={[styles.h1, style]}>{children}</Text>
    )
}

const styles = StyleSheet.create({
  h1: {
    fontFamily: DefaultTheme.FONTS.Sari,
    textAlign: "center",
    fontWeight:700,
    fontSize: DefaultTheme.FONT_SIZES.h1,
    color: DefaultTheme.COLORS.grey5,
    marginBottom: 15,
  },
});

export default H1Title;