import axios from "axios";
import moment from "moment";
import RandExp from "randexp";
import Config from "../config/config"
import { Buffer } from "buffer";
import SecureLocalStorage from "react-secure-storage";
import utils from "../constants/utils/validate"
import localStorageTokens from "../localStorage/tokens";
import localStorageUser from "../localStorage/user";

const API_URL = Config.authURL;
const LENGTH_SECRET = parseInt(process.env.LENGTH_SECRET) || 80;

const fetch = async (method, url, data = undefined, h = {}) => {
  const headers = {
    secret: generateSecret().result,
    "reckon-origin": Config.dns_app,
    ...h,
  };

  var options = {
    method: method,
    url: url,
    headers: headers,
    data,
    timeout: 30000,
  };
  // console.log(generateSecret().result, method, url, data, options);

  let response = await axios(options)
    .then((r) => utils.successResult(true, r))
    .catch((r) => utils.successResult(false, { ...r, noResponse: true }));

  if (response.success) {
    response = response.result.data;
  }
  // console.log("---------Auth Response------------");
  // console.log(JSON.stringify(response));
  return response;
};

const getCurrentUser = () => {
  // console.log(localStorageUser.getUser())
  return localStorageUser.getUser()?.result;
};

//data: {username, phone, password, role: user}
const register = async (user) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...user },
    url: "user",
    method: "POST",
  });

  return res;
};

//data: {username, phone, password, role: user}
const registerUser = async (user) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...user },
    url: "user",
    method: "POST",
  });
  return res;
};

//data: {phone || idUser; code;}
const validateUser = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "validate/user",
    method: "PUT",
  });

  if (res.success) {
    localStorageUser.deleteUser();
    localStorageUser.setUser(
      res.result.user
    );
  }
  return res;
};

//data: {phone || idUser}
const newValidationCode = (data) => {
  return fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "validate/user",
    method: "GET",
  });
};

// delete Account

const deleteUser = async (data, id) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { new: { ...data }, idUser: id },
    url: "user/toDelete",
    method: "PUT",
  });
  if (res.success) {
    localStorageUser.deleteUser();
  }
  return res;
};

//data: {phone || idUser}
const getGenericCode = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user/sms",
    method: "POST",
  });
  if (res.success) {
    await AsyncStorage.setItem("idValidation", JSON.stringify(res.result));
  }
  return res;
};

//data: {idValidation, validation}
const validateGenericCode = async (data) => {
  const idValidation = JSON.parse(localStorage.getItem("idValidation"));
  const res = await fetch("POST", API_URL + "/send", {
    data: { idValidation, ...data },
    url: "user/validate/sms",
    method: "GET",
  });
  if (res.success)
    localStorage.setItem("user", JSON.stringify(res.result.user));

  return res;
};

//data: {phone || idUser}
const userToken = async (data) => {
  const res = await fetch("GET", API_URL + "/send", {
    data: { ...data },
    url: "user/token",
    method: "GET",
  });
  if (res.success) {
    await AsyncStorage.setItem("mytkn", JSON.stringify(res.data.result));
  }
};

const validateUserToken = () => {
  return fetch("POST", API_URL + "/validate", {
    token: JSON.parse(AsyncStorage.getItem("mytkn")),
  });
};

//data: {service || dns || idApp, data: {....}}
const encryptData = async (data) => {
  return await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "app/token",
    method: "GET",
  });
};

const getRoles = async () => {
  return await fetch("POST", API_URL + "/send", {
    url: "roles",
    method: "GET",
  });
};

const getOrganizations = async () => {
  return await fetch("POST", API_URL + "/send", {
    url: "organizations",
    method: "GET",
  });
};

const users = async (data) => {
  return await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "users",
    method: "GET",
  });
};

const getUser = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user",
    method: "GET"
  });
  if (res.success) {
    await localStorageUser.setUser({
      ...res.result,
      // commented because getUser do not reply the token || token: `${res.result.token}`,
    });
  }
  return res;
};

//data: {phone || idUser, new: {...}}
const updateUser = async (data, id) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { new: { ...data }, idUser: id },
    url: "user",
    method: "PUT",
  });
  return res;
};


const updateRole = (data, id) => {
  return fetch("POST", API_URL + "/send", {
    data: { role: data, idUser: id },
    url: "user/change/role",
    method: "PUT",
  });
};

const updateOrganization = (data, id) => {
  return fetch("POST", API_URL + "/send", {
    data: { organization: data, idUser: id },
    url: "user/change/organization",
    method: "PUT",
  });
};

const activeUser = (id) => {
  return fetch("POST", API_URL + "/send", {
    data: { idUser: id },
    url: "/user/active",
    method: "PUT",
  });
}

const deleteToken = async (token) => {
  await AsyncStorage.removeItem(token);
};

//data: {phone || idUser, password}}
const login = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user/login",
    method: "GET",
  });
  if (res.success && !res.result.user.toDelete) {
    localStorageUser.setUser({
      ...res.result.user,
      token: `${res.result.token}`,
    }
    );
    localStorageUser.setUserLanguage(res.result.user.locale);
  }
  return res;
};

const setCurrentUser = (data, token) => {
  deleteToken("authToken");
  AsyncStorage.clear();
  AsyncStorage.setItem(
    "user",
    JSON.stringify({
      ...data,
      token: `${token}`,
    })
  );
  AsyncStorage.setItem(
    "mytkn",
    JSON.stringify({ token: `${token}` })
  );
}

const logout = () => {
  SecureLocalStorage.removeItem("user");
  SecureLocalStorage.removeItem("token");
};

const successResult = (success, result) => {
  return { success, result };
};
const base64Encoded = (data) => {
  try {
    return successResult(
      true,
      new Buffer.from(data, "utf8").toString("base64")
    );
  } catch (e) {
    return successResult(false, "Error");
  }
};

const generateSecret = () => {
  function invertNumber(data) {
    try {
      if (data.length === 1) data = "0" + data;
      return data.split("").reverse().join("");
    } catch (e) {
      throw e;
    }
  }
  try {
    const date = moment().utc(false);
    let year = date.format("Y");
    let newYear = "";
    for (let i = 0; i < year.length; i++) {
      if (i % 2) newYear += year[i];
      else newYear += `[a-zA-Z]{${year[i]}}`;
    }
    const month = date.format("M");
    const day = date.format("D");
    const hour = invertNumber(date.format("H"));
    const minute = invertNumber(date.format("m"));
    const second = invertNumber(date.format("s"));
    let secret = new RandExp(
      `${newYear}:${hour}[a-zA-Z]{${month}}${minute}[a-zA-Z]{${day}}${second}`
    ).gen();
    secret += new RandExp(
      `[a-zA-Z]{${1}[a-zA-Z0-9]{${LENGTH_SECRET - secret.length - 1}`
    ).gen();
    return base64Encoded(secret);
  } catch (e) {
    return successResult(false, e);
  }
};

const verifyCurrentPassword = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user/login",
    method: "GET",
  });
  return res;
};

export default {
  register,
  registerUser,
  fetch,
  login,
  logout,
  generateSecret,
  setCurrentUser,
  validateUser,
  validateUserToken,
  getGenericCode,
  encryptData,
  getRoles,
  users,
  getUser,
  updateUser,
  activeUser,
  updateRole,
  updateOrganization,
  newValidationCode,
  validateGenericCode,
  userToken,
  getOrganizations,
  verifyCurrentPassword,
  deleteUser,
  getCurrentUser
};