import React, { useState, useContext, useEffect } from "react";
import DefaultTheme from "../../themes";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
    View,
    StyleSheet,
} from "react-native-web";
import { ReactComponent as SvgComponent } from "../../assets/images/authentication/login.svg";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import { AuthContext } from "../../store/auth-context";
import SmallParagraph from "../../components/UI/SmallParagraph";
import CustomTextInput from "../../components/UI/CustomTextInput";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import localStorageUser from "../../localStorage/user";
import AuthService from "../../services/auth-service";
import BuybyeService from "../../services/buybye-service";
import { noResponseParams, notOkPageParams } from "../../components/Helpers/ResultMessagesHelper";

export default function ReceiptByEmail() {
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const values = queryString.parse(search);
    const cardToken = values.card;
    const purchaseId = values.purchase;

    const [email, onChangeEmail] = useState("");
    const [id, setId] = useState("");
    const [otherIdentifiers, setOtherIdentifiers] = useState("");
    const [showErrorUserEmptyMessage, setShowErrorUserEmptyMessage] =
      useState(false);
    // const [id, setID] = useState("");
    // const [secureTextEntry, setSecureTextEntry] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorInvalidUserMessage, setShowErrorInvalidUserMessage] = useState(false);
    // const [keepLoggedIn, setkeepLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);

    const authCtx = useContext(AuthContext);

    const userIsEmpty = email?.trim().length === 0;
    const EMAIL_REGEX = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const emailIsValid = email.trim().length > 0 && EMAIL_REGEX.test(email);
    
    useEffect(() => {
        const getUserFromStorage = async() => {
        const getUser = await localStorageUser.getUser();
        if (typeof getUser.result === "string") {
          getUser.result = JSON.parse(getUser.result);
        }
        if (getUser.result) {
          setId(getUser.result._id);
          setOtherIdentifiers(getUser.result.otherIdentifiers);
        }
      }
      getUserFromStorage();
    }, []);
    
    function showMessageHelper(a){
        setShowErrorInvalidUserMessage(a);
        setShowErrorUserEmptyMessage(!a);
    }

    async function onClickHandler() {
        userIsEmpty
          ? showMessageHelper(false)
          : emailIsValid
          ? setShowErrorInvalidUserMessage(false)
          : showMessageHelper(true);
        if (emailIsValid) {
            history.push({
              pathname: "/SignUp",
              email: email,
              card: cardToken,
              purchase: purchaseId
            });
        } 
    }

    async function sendEmailHandler(){
        userIsEmpty
          ? showMessageHelper(false)
          : emailIsValid
          ? setShowErrorInvalidUserMessage(false)
          : showMessageHelper(true);
        if (emailIsValid) {
          let res = await BuybyeService.sendEmailReceipt(purchaseId, {contact: email});
          // console.log(res)
          if(res.success){
              history.push({
                pathname: "/ReceiptByEmail/ReceiptSent",
                email: email,
                card: cardToken,
                purchase: purchaseId
              });
          } else {
            history.push("/error", {
              params: notOkPageParams(
                res.errorMessage.message +
                  "\n " +
                  t("resultScreens:tryAgainLater")
              ),
            });
          }
        } 
    }

    async function associateToLoggedAccountHandler() {
        setLoading(true);
        let res = await AuthService.updateUser(
          { otherIdentifiers: [...otherIdentifiers, cardToken] },
          id
        );
        if (res.success) {
          const getNewUserData = await AuthService.getUser({ idUser: id });
          history.push({
            pathname: "/Purchases",
            card: cardToken,
            purchase: purchaseId,
          });
        } else if (res.errorMessage.noResponse) {
          history.push("/error", {
            params: noResponseParams,
          });
        } else {
          history.push("/error", {
            params: notOkPageParams(
              res.errorMessage.message +
                "\n " +
                t("resultScreens:tryAgainLater")
            ),
          });
        }
        let sendEmail = await BuybyeService.sendEmailReceipt(purchaseId, {
          contact: email,
          registered: true
        });
        // console.log("sendEmail", sendEmail);
        setLoading(false);
    }

    return (
      <AppLayout
        headerBack={false}
        history={history}
        withScrollview={false}
        styleToContainer={{ paddingTop: "5vh" }}
      >
        <View style={{ display: "flex", height: "100%" }}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              maxHeight: "30%",
            }}
          >
            <SvgComponent style={{ maxHeight: "100%" }} />
          </View>
          <View style={{ marginTop: "8vh", marginBottom: "5vh" }}>
            <H1Title>Send receipt by email</H1Title>
            <Paragraph>
              Receive your receipt on your email. You can also create an account
              that gives you access to all of your purchases.
            </Paragraph>
          </View>
          <View
            style={{
              justifyContent: "flex-start",
              marginTop: "15px",
            }}
          >
            <View
              style={{
                paddingBottom:
                  showErrorUserEmptyMessage || showErrorInvalidUserMessage
                    ? 0
                    : 28,
              }}
            >
              <CustomTextInput
                style={[
                  showErrorMessage ||
                  showErrorInvalidUserMessage ||
                  showErrorUserEmptyMessage
                    ? { borderBottomColor: DefaultTheme.COLORS.error }
                    : null,
                ]}
                onChangeText={onChangeEmail}
                value={email}
                placeholder={t("authentication:emailAddress") + "*"}
                keyboardType="email-address"
                textContentType="emailAddress"
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect={false}
                onEndEditing={() => {
                  if (userIsEmpty) {
                    setShowErrorUserEmptyMessage(true);
                  } else {
                    setShowErrorUserEmptyMessage(false);
                  }
                }}
              />
              {(showErrorUserEmptyMessage || showErrorInvalidUserMessage) && (
                <SmallParagraph style={{ color: DefaultTheme.COLORS.error }}>
                  {showErrorUserEmptyMessage && t("errors:emailEmpty") + " "}
                  {showErrorInvalidUserMessage && t("errors:emailInvalid")}
                </SmallParagraph>
              )}
            </View>
          </View>
          <View
            style={{
              paddingBottom: 20,
            }}
          >
            <Button
              text={"Send receipt & Create account"}
              onPress={onClickHandler}
              type="PRIMARY"
            />
            <Button
              text={"Send receipt by email"}
              onPress={sendEmailHandler}
              type="SECONDARY"
            />
            <Paragraph style={{ marginTop: "5vh" }}>
              If you already have an account tap the link bellow
            </Paragraph>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 24,
              }}
            >
              {authCtx.isAuthenticated ? (
                <Button
                  text={"Associate this payment to my account"}
                  onPress={associateToLoggedAccountHandler}
                  type="LINK"
                  element={loading ? "" : null}
                />
              ) : (
                <Button
                  text={"I have already an account"}
                  onPress={() => {
                    history.push({
                      pathname: "/SignIn",
                      email: email,
                      card: cardToken,
                      purchase: purchaseId,
                    });
                  }}
                  type="LINK"
                />
              )}
            </View>
          </View>
        </View>
      </AppLayout>
    );
}

const styles = StyleSheet.create({
    password_container: {
        fontFamily: DefaultTheme.FONTS.Regular,
        height: 40,
        borderBottomWidth: 1,
        borderColor: DefaultTheme.COLORS.grey5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
});
