import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import AuthService from "../../services/auth-service";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import { useHistory } from "react-router-dom";
import CustomTextInput from "../../components/UI/CustomTextInput";
import SmallParagraph from "../../components/UI/SmallParagraph";
import { noResponseParams } from "../../components/Helpers/ResultMessagesHelper";

export default function ForgotPassword1({ navigation, route }) {
  const { t } = useTranslation();
  const history = useHistory();
  const filledEmail = history.location?.email;
  const [email, onChangeEmail] = useState(filledEmail || "");
  const [showEmailInvalidMsg, setShowEmailInvalidMsg] = useState(false);
  const [showEmailRequiredMsg, setShowEmailRequiredMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const emailIsValid = email.trim().length > 0 && EMAIL_REGEX.test(email);
  
  async function onSubmitValidation() {
    setLoading(true);

    if (!emailIsValid) {
      !emailIsValid
        ? setShowEmailRequiredMsg(true)
        : setShowEmailRequiredMsg(false);
      setLoading(false);
      return false;
    }

    setShowEmailRequiredMsg(false);
    setShowEmailInvalidMsg(false);

    const res = await AuthService.newValidationCode({
      email: email,
    });
    if (res.success) {
      setShowEmailInvalidMsg(false);
      // console.log(email)
      history.push({
        pathname: '/ForgotPassword/Verify',
        email: email
      });
    } else if (res.errorMessage.noResponse) {
      history.push("/error", {
        params: noResponseParams,
      });
    } else {
      setShowEmailInvalidMsg(true);
    }
    setLoading(false);
  }

  return (
    <AppLayout
      headerLabel={t("common:headerForgotPassword")}
      headerBack={true}
      headerBackName={"SignIn"}
      route={route}
      history={history}
      style={{backgroundColor:"#fff"}}
    >

      <View
        style={{
          marginTop: "18px",
          marginBottom:
            showEmailRequiredMsg ||
              showEmailInvalidMsg
              ? 24.4
              : 52,
        }}
      >
        <CustomTextInput
          onChangeText={onChangeEmail}
          onEndEditing={() => {
            if (email.trim().length > 0 && !EMAIL_REGEX.test(email)) {
              setShowEmailInvalidMsg(true);
            } else {
              setShowEmailInvalidMsg(false);
              setShowEmailRequiredMsg(false);
            }
          }}
          value={email}
          placeholder={t("authentication:emailAddress") + "*"}
          textContentType="emailAddress"
          inputMode="email"
          keyboardType="email-address"
          autoCapitalize="none"
          autoComplete="email"
        />
        {(showEmailRequiredMsg ||
          showEmailInvalidMsg) && (
            <SmallParagraph>
              {showEmailRequiredMsg && (
                <Text style={{ color: DefaultTheme.COLORS.error }}>{t("errors:required")}</Text>
              )}
              {showEmailInvalidMsg && (
                <Text style={{ color: DefaultTheme.COLORS.error }}>
                  {t("errors:emailInvalid")}
                </Text>
              )}
            </SmallParagraph>
          )}
      </View>
      <BottomBtnContainer>
        <Button
          text={t("buttons:next")}
          element={email}
          onPress={onSubmitValidation}
          type="PRIMARY"
          loading={loading}
        />
      </BottomBtnContainer>
    </AppLayout>
  );
}

const styles = StyleSheet.create({
});
